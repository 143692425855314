import { useState, useEffect, useContext } from "react";
import { ORIGIN } from "../App";
import { Typography, Box, Button, AppBar, Toolbar, useTheme, Menu, MenuItem, Card, IconButton, Modal } from "@mui/material";
// import { PropertyRow } from "./PropertyRow";
import { Add, ArrowDropDown, ArrowDropUp, Close, Download, Search } from '@mui/icons-material';
import { useMsal } from '@azure/msal-react';
import colors from '../lib/colors';
import { Link, useNavigate } from "react-router-dom";
import { DataGrid, GridColDef, GridEventListener } from "@mui/x-data-grid";
import { SidebarWidthContext } from "./Page";
import { PROPERTY_API_PATH } from "../lib/apiPaths";
import { READ_SCOPE } from "../lib/scopes";
import { ExpenseDTO, HOAPolicyDTO, LeaseDTO, OwnerDTO, PropertyDTO, UtilityCompanyDTO } from "../lib/dataTransferObjects";
import { DownloadFile, getOrdinalSuffix, USDollarFormatter } from "../lib/utils";
import propertyIllustration from '../images/property_illustration.svg';
import { DateTime } from "luxon";
import { IdTokenClaims } from "../authConfig";
import { modalStyle } from "../lib/styles";
import { useLanguage } from "../LanguageContext";

interface PropertyRow {
    id: number,
    address: string,
    city: string,
    county: string,
    zip: string,
    state: string,
    dateAdded: string,
    owner: string,
    tenantName: string,
    tenantPhone: string,
    tenantEmail: string,
    leaseStart: string,
    leaseEnd: string,
    rentDueDate: number,
    rent: number,
    securityDeposit: number,
}

function PropertyToRow(p: Property) {
    let tenantName = "No Tenants";
    let tenantPhone = "None";
    let tenantEmail = "None";
    if (p.lease.tenants.length > 0) {
        tenantName = p.lease.tenants[0].firstName + " " + p.lease.tenants[0].lastName;
        tenantPhone = p.lease.tenants[0].phoneNumber;
        tenantEmail = p.lease.tenants[0].email;
    }
    let ownerName = "No Owner";
    if (p.owners.length > 0) ownerName = p.owners[0].firstName + " " + p.owners[0].lastName;
    return {
        id: p.id,
        address: p.streetNumber + " " + p.streetName,
        city: p.city,
        county: p.county,
        zip: p.zipCode,
        state: p.state,
        dateAdded: p.created.getTime() == DOTNET_DATETIME_DEFAULT_AS_MS_FROM_EPOCH ? "N/A" : p.created.toLocaleDateString(),
        owner: ownerName,
        tenantName: tenantName,
        tenantPhone: tenantPhone,
        tenantEmail: tenantEmail,
        leaseStart: p.lease.leaseStart,
        leaseEnd: p.lease.leaseEnd,
        rentDueDate: p.lease.dueDate,
        rent: p.lease.rent,
        securityDeposit: p.lease.securityDeposit,
    }
}

const columns: GridColDef[] = [
    {
        field: 'address',
        headerName: 'Address',
        flex: 1,
    },
    {
        field: 'city',
        headerName: 'City',
        flex: 1
    },
    {
        field: 'county',
        headerName: 'County',
        flex: 1
    },
    {
        field: 'zip',
        headerName: 'Zip',
        flex: 1
    },
    {
        field: 'state',
        headerName: 'State',
        flex: 1
    },
    {
        field: 'dateAdded',
        headerName: 'Date Added',
        flex: 1
    },
    {
        field: 'owner',
        headerName: 'Owner',
        flex: 1
    },
    {
        field: 'tenantName',
        headerName: 'Tenant',
        flex: 1
    },
    {
        field: 'tenantPhone',
        headerName: 'Tenant Phone',
        flex: 1
    },
    {
        field: 'tenantEmail',
        headerName: 'Tenant Email',
        flex: 1
    },
    {
        field: 'leaseStart',
        headerName: 'Lease Start',
        flex: 1,
        valueFormatter(params) {
            return new Date(params.value).toLocaleDateString();
        },
    },
    {
        field: 'leaseEnd',
        headerName: 'Lease End',
        flex: 1,
        valueFormatter(params) {
            return new Date(params.value).toLocaleDateString();
        },
    },
    {
        field: 'rentDueDate',
        headerName: 'Rent Due Date',
        flex: 1,
        valueFormatter(params) {
            return params.value + getOrdinalSuffix(params.value);
        },
    },
    {
        field: 'rent',
        headerName: 'Rent',
        flex: 1,
        valueFormatter(params) {
            return USDollarFormatter.format(params.value);
        },
    },
    {
        field: 'securityDeposit',
        headerName: 'Security Deposit',
        flex: 1,
        valueFormatter(params) {
            return USDollarFormatter.format(params.value);
        },
    },
];

enum PropertyType {
    All,
    Managed,
    Owned,
}

const PropertyTypeMenuNames = ["All properties", "Managed Properties", "Owned Properties"];
const DOTNET_DATETIME_DEFAULT_AS_MS_FROM_EPOCH = -62135568422000;

export interface Property {
    id: number,
    created: Date,
    streetNumber: string,
    streetName: string,
    owners: OwnerDTO[],
    expenses: ExpenseDTO[],
    city: string,
    state: string,
    zipCode: string,
    county: string,
    lease: LeaseDTO,
    hoaPolicy: HOAPolicyDTO,
    electricity: UtilityCompanyDTO,
    water: UtilityCompanyDTO,
    gas: UtilityCompanyDTO,
    trash: UtilityCompanyDTO,
}

export function PropertyList() {
    const navigate = useNavigate();
    const theme = useTheme();
    const { instance, accounts } = useMsal();
    const user = accounts[0];
    const [managedProperties, setManagedProperties] = useState<Property[] | null>(null);
    const [ownedProperties, setOwnedProperties] = useState<Property[] | null>(null);
    const [displayedProperties, setDisplayedProperties] = useState<Property[] | null>(null);
    const [propertyType, setPropertyType] = useState<PropertyType>(PropertyType.All);
    const [propertyTypeMenuAnchorEl, setPropertyTypeMenuAnchorEl] = useState<null | HTMLElement>(null);
    const propertyTypeMenuOpen = Boolean(propertyTypeMenuAnchorEl);
    const [displayedRows, setDisplayedRows] = useState<PropertyRow[]>([]);
    const [managedPropertyRows, setManagedPropertyRows] = useState<PropertyRow[]>([]);
    const [ownedPropertyRows, setOwnedPropertyRows] = useState<PropertyRow[]>([]);
    const navDrawerWidth = useContext(SidebarWidthContext);
    // const [limitReachedModalOpen, setLimitReachedModalOpen] = useState(false);
    // const [propertyLimit, setPropertyLimit] = useState<number>(0);
    // const [subscriptionStatus, setSubscriptionStatus] = useState<string>("");
    const {langpack, setLanguage} = useLanguage();

    const handleClickPropertyTypeMenu = (event: React.MouseEvent<HTMLElement>) => {
        setPropertyTypeMenuAnchorEl(event.currentTarget);
    };

    const handleClosePropertyTypeMenu = (selection: PropertyType) => {
        switch (selection) {
            case PropertyType.All: {
                setDisplayedProperties((managedProperties ?? []).concat(ownedProperties ?? []))
                setDisplayedRows(managedPropertyRows.concat(ownedPropertyRows));
                break;
            }
            case PropertyType.Managed: {
                setDisplayedProperties(managedProperties);
                setDisplayedRows(managedPropertyRows);
                break;
            }
            case PropertyType.Owned: {
                setDisplayedProperties(ownedProperties);
                setDisplayedRows(ownedPropertyRows);

                break;
            }
            default: {
                break;
            }
        }
        setPropertyType(selection);
        setPropertyTypeMenuAnchorEl(null);
    };

    async function FetchProperties() {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [READ_SCOPE],
            account: user,
            forceRefresh: true
        });
        const claims: IdTokenClaims = accessToken.idTokenClaims;
        // setPropertyLimit(parseInt(claims.propertyLimit ?? "15"));
        // setSubscriptionStatus(claims.subscriptionStatus ?? "paid");
        const response = await fetch(ORIGIN + PROPERTY_API_PATH, {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken
            },
            credentials: "include",
            mode: "cors",
        });
        let body: PropertyDTO[] = await response.json();
        let newProperties: Property[] = body.map(p => {
            return {
                ...p,
                created: new Date(p.created)
            }
        });
        let newPropertyRows: PropertyRow[] = newProperties.map(PropertyToRow);
        let newManagedProperties: Property[] = [];
        let newOwnedProperties: Property[] = [];
        let newManagedPropertyRows: PropertyRow[] = [];
        let newOwnedPropertyRows: PropertyRow[] = [];
        newProperties.forEach(p => {
            if (p.owners[0].email == user.username) {
                newOwnedProperties.push(p);
                newOwnedPropertyRows.push(PropertyToRow(p));
            }
            else {
                newManagedProperties.push(p);
                newManagedPropertyRows.push(PropertyToRow(p));
            }
        });
        setManagedProperties(newManagedProperties);
        setManagedPropertyRows(newManagedPropertyRows);
        setOwnedProperties(newOwnedProperties);
        setOwnedPropertyRows(newOwnedPropertyRows);
        setDisplayedProperties(newProperties);
        setDisplayedRows(newPropertyRows);
    };

    useEffect(() => {
        if (managedProperties == null) {
            FetchProperties();
        }
    }, [managedProperties]);

    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        navigate(params.id.toString())
    };

    function ExportCSV() {
        let titleRow = [
            "Address",
            "City",
            "State",
            "ZIP Code",
            "County",
            "Owner Name",
            "Owner Phone",
            "Owner Email",
            "Tenant Names",
            "Tenant Emails",
            "Tenant Phones",
            "Occupant Names",
            "Occupant Emails",
            "Occupant Phones",
            "Lease Start",
            "Lease End",
            "Rent",
            "Security Deposit",
            "Due Date",
            "HOA Name",
            "HOA Website",
            "HOA Phone",
            "HOA Autopay",
            "HOA Annual Fee",
            "HOA Monthly Fee",
            "HOA Note",
            "Electric Name",
            "Electric Phone",
            "Electric Website",
            "Electric Note",
            "Electric Autopay",
            "Water Name",
            "Water Phone",
            "Water Website",
            "Water Note",
            "Water Autopay",
            "Gas Name",
            "Gas Phone",
            "Gas Website",
            "Gas Note",
            "Gas Autopay",
            "Trash Name",
            "Trash Phone",
            "Trash Website",
            "Trash Note",
            "Trash Autopay"
        ]
        let csvRows = displayedProperties?.map((property: Property) => {
            return [
                property.streetNumber + " " + property.streetName,
                property.city,
                property.state,
                property.zipCode,
                property.county,
                property.owners[0].firstName + " " + property.owners[0].lastName,
                property.owners[0].phoneNumber,
                property.owners[0].email,
                property.lease.tenants.map((tenant) => tenant.firstName + " " + tenant.lastName).join(" | "),
                property.lease.tenants.map((tenant) => tenant.email).join(" | "),
                property.lease.tenants.map((tenant) => tenant.phoneNumber).join(" | "),
                property.lease.occupants.map((occupant) => occupant.firstName + " " + occupant.lastName).join(" | "),
                property.lease.occupants.map((occupant) => occupant.email).join(" | "),
                property.lease.occupants.map((occupant) => occupant.phone).join(" | "),
                DateTime.fromISO(property.lease.leaseStart).toISODate(),
                DateTime.fromISO(property.lease.leaseEnd).toISODate(),
                "$" + property.lease.rent,
                "$" + property.lease.securityDeposit,
                property.lease.dueDate + getOrdinalSuffix(property.lease.dueDate),
                property.hoaPolicy.associationName,
                property.hoaPolicy.website,
                property.hoaPolicy.phone,
                property.hoaPolicy.autoPay,
                "$" + (property.hoaPolicy.annualFee ?? 0),
                "$" + (property.hoaPolicy.monthlyFee ?? 0),
                property.hoaPolicy.note,
                property.electricity.companyName,
                property.electricity.phone ?? "",
                property.electricity.website ?? "",
                property.electricity.note,
                property.electricity.autoPay,
                property.water.companyName,
                property.water.phone ?? "",
                property.water.website ?? "",
                property.water.note,
                property.water.autoPay,
                property.gas.companyName,
                property.gas.phone ?? "",
                property.gas.website ?? "",
                property.gas.note,
                property.gas.autoPay,
                property.trash.companyName,
                property.trash.phone ?? "",
                property.trash.website ?? "",
                property.trash.note,
                property.trash.autoPay,
            ];
        });
        const csvData = [titleRow, csvRows?.join('\n')].join('\n');
        const csvBlob = new Blob([csvData], { type: 'text/csv' });
        const csvFile = new File([csvBlob], "properties.csv", { type: 'text/csv' });
        DownloadFile(csvFile);
    }

    // async function AddProperty() {
    //     if (subscriptionStatus === "perProperty" && (displayedProperties?.length ?? 0) >= propertyLimit) {
    //         setLimitReachedModalOpen(true);
    //     }
    //     else navigate("Add");
    // }

    return (
        <Box sx={{ flex: "1", overflowX: "clip" }} >
            {/* <Modal
                open={limitReachedModalOpen}
                onClose={() => setLimitReachedModalOpen(false)}
            >
                <Card sx={{ ...modalStyle, width: 660 }}>
                    <Box display="flex" flexDirection="column" alignItems="center" >
                        <Box width="100%" display="flex" flexDirection="row" alignItems="center" gap={theme.spacing(0.5)}>
                            <Box flex={1} />
                            <IconButton onClick={() => setLimitReachedModalOpen(false)} sx={{ backgroundColor: "white" }}>
                                <Close />
                            </IconButton>
                        </Box>
                        <Typography fontSize="28px" fontWeight={500} lineHeight="30px" textAlign="center">Limit Reached</Typography>
                        <Box margin={theme.spacing(1)} display="flex" flexDirection="row" alignItems="center">
                            <Typography>Increase your property limit in your</Typography>
                            <Link style={{ fontSize: "16px", fontWeight: 500, color: colors.greyDarkest, margin: theme.spacing(0.5) }}
                                to={"../Account/"}>
                                Account
                            </Link>
                            <Typography>page to add more properties.</Typography>
                        </Box>
                    </Box>
                </Card>
            </Modal> */}
            <AppBar position='sticky' elevation={0} sx={{ bgcolor: colors.SchemesSurfaceContainerLow, width: `calc(100vw - ${navDrawerWidth}px)` }}>
                <Toolbar sx={{ margin: `${theme.spacing(2)} ${theme.spacing(4)} 0`, bgcolor: "white", borderRadius: theme.spacing(4), height: "84px" }}>
                    <Typography variant="h4" marginRight={theme.spacing(1)}>{managedProperties === null ? "Loading..." : (displayedProperties?.length + " " + (displayedProperties?.length === 1 ? langpack.property : langpack.properties))}</Typography>
                    {/* <Typography fontWeight={400} fontSize="16px" color={colors.grey}>{managedProperties === null ? "" : displayedProperties?.length + " / " + propertyLimit }</Typography> */}
                    <Box flex={1} />
                    <Box gap={theme.spacing(1)} display="flex" flexDirection="row">
                        {/* <TextField
                            placeholder='Search'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" sx={{padding: theme.spacing(1)}}>
                                        <Search />
                                    </InputAdornment>
                                ),
                                disableUnderline: true,
                                sx: {
                                    borderRadius: "100px",
                                    background: colors.SchemesSurfaceContainerHigh,
                                    width: "360px",
                                    height: "48px"
                                }
                            }}
                            variant='standard'
                        /> */}
                        {/* <Button sx={{ height: "48px" }} variant="contained" color='secondary' onClick={handleClickPropertyTypeMenu} startIcon={propertyTypeMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />}>
                            {PropertyTypeMenuNames[propertyType]}
                        </Button>
                        <Menu
                            anchorEl={propertyTypeMenuAnchorEl}
                            open={propertyTypeMenuOpen}
                            onClose={() => handleClosePropertyTypeMenu(propertyType)}
                        >
                            <MenuItem onClick={() => handleClosePropertyTypeMenu(PropertyType.All)} disableRipple>
                                {PropertyTypeMenuNames[PropertyType.All]}
                            </MenuItem>
                            <MenuItem onClick={() => handleClosePropertyTypeMenu(PropertyType.Managed)} disableRipple>
                                {PropertyTypeMenuNames[PropertyType.Managed]}
                            </MenuItem>
                            <MenuItem onClick={() => handleClosePropertyTypeMenu(PropertyType.Owned)} disableRipple>
                                {PropertyTypeMenuNames[PropertyType.Owned]}
                            </MenuItem>
                            </Menu> */}
                        <Button startIcon={<Download />} variant='contained' color="secondary" onClick={() => ExportCSV()}>{langpack.downloadpropertydata}</Button>
                        <Button startIcon={<Add />} variant='contained' onClick={() => navigate("Add")}>{langpack.addproperty}</Button>
                    </Box>
                </Toolbar>
            </AppBar>
            <Box height={`calc(100vh - 84px - ${theme.spacing(2)})`} padding={`${theme.spacing(2)} ${theme.spacing(4)}`}>
                <Card sx={{
                    padding: theme.spacing(3),
                    background: "white",
                    border: 0,
                    height: "100%",
                }}>
                    {
                        managedProperties === null || ownedProperties === null ?
                            <Typography margin={theme.spacing(6)} fontWeight={350} textAlign="center" variant="h3">Loading...</Typography> :
                            (
                                displayedRows.length === 0 ?
                                    <Box display="flex" flexDirection="column">
                                        <Box component="img" src={propertyIllustration} height="50vh" marginTop={theme.spacing(4)} />
                                        <Typography margin={theme.spacing(6)} fontWeight={350} textAlign="center" variant="h3">{langpack.nopropertiesyet}</Typography>
                                    </Box>
                                    :
                                    <DataGrid
                                        columns={columns}
                                        rows={displayedRows}
                                        hideFooter={displayedRows.length < 25}
                                        sx={{
                                            "& .MuiDataGrid-row:hover": {
                                                cursor: "pointer"
                                            },
                                            '& .MuiDataGrid-cell:focus': {
                                                outline: 'none',
                                            },
                                            '.MuiDataGrid-columnSeparator': {
                                                display: 'none',
                                            },
                                            '.MuiDataGrid-columnHeaderTitle': {
                                                fontSize: "16px",
                                                color: colors.greyVioletDark
                                            },
                                            '& .MuiDataGrid-columnHeader': {
                                                '& .MuiDataGrid-iconButtonContainer': {
                                                    marginLeft: '8px'
                                                },
                                            },
                                            border: "0px"
                                        }}
                                        onRowClick={handleRowClick}
                                        disableRowSelectionOnClick
                                        initialState={{
                                            columns: {
                                                columnVisibilityModel:
                                                {
                                                    county: false,
                                                    zip: false,
                                                    state: false,
                                                    dateAdded: false,
                                                    leaseStart: false,
                                                    rentDueDate: false,
                                                    securityDeposit: false,
                                                }
                                            },
                                            sorting: {
                                                sortModel: [{ field: 'address', sort: 'asc' }],
                                            }
                                        }}
                                    />
                            )
                    }
                </Card>
            </Box>
        </Box>
    )
}