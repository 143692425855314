import { createContext, ReactNode, useEffect, useState } from "react";
import { isMobile } from "./mobileCheck";
import { Loading } from "./components/Loading";
import { DELAY } from "./App";

interface Props {
  children: ReactNode
}

export const IsMobileContext = createContext<boolean>(isMobile());

export function MobileValidator({ children }: Props) {
  const [mobile, setMobile] = useState<boolean | null>(null);

  async function delay() {
    await new Promise(r => setTimeout(r, 1000));
    if (mobile === null) setMobile(isMobile());
  }

  useEffect(() => {
    if(DELAY) delay();
    else {
      if (mobile === null) setMobile(isMobile());
    }
  }, [mobile]);

  console.log(mobile);

  return (
    <IsMobileContext.Provider value={mobile ?? false}>
      {mobile === null ? <Loading /> : children}
    </IsMobileContext.Provider>
  );
}