import { AppBar, Box, Button, Card, Grid, IconButton, TextField, Toolbar, Tooltip, Typography, useTheme } from "@mui/material";
import colors from "../../lib/colors";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { ORIGIN, STRIPE_MANAGEMENT_URL } from "../../App";
import { IdTokenClaims } from "../../authConfig";
import { Edit, Logout, Save } from "@mui/icons-material";
import { READ_SCOPE, USER_READWRITE_MS_GRAPH_SCOPE } from "../../lib/scopes";
import { STRIPE_API_PATH, USER_GRAPH_URL } from "../../lib/apiPaths";
import { StripeSubscription } from "../../lib/stripeInterfaces";

interface AccountFields {
    displayName: string,
    givenName: string,
    surname: string,
    email: string,
    city: string,
    state: string,
    // subscriptionType: string,
    // subscriptionQuantity: number,
    // monthlyCost: number
}

export function MobileAccount() {
    const theme = useTheme();
    const { instance, accounts } = useMsal();
    const user = accounts[0];
    const [editing, setEditing] = useState(false);
    const [fields, setFields] = useState<AccountFields>({
        displayName: "",
        givenName: "",
        surname: "",
        email: "",
        city: "",
        state: "",
        // subscriptionType: "",
        // subscriptionQuantity: 1,
        // monthlyCost: 0,
    });

    async function SetAccountFields() {
        const accessToken = await instance.acquireTokenSilent({
            scopes: [READ_SCOPE],
            account: user
        });

        // const subscriptionResponse = await fetch(ORIGIN + STRIPE_API_PATH + "Subscription", {
        //     method: "GET", // *GET, POST, PUT, DELETE, etc.
        //     headers: {
        //         "Content-Type": "application/json",
        //         "Authorization": "Bearer " + accessToken.accessToken
        //     },
        //     credentials: "include",
        //     mode: "cors",
        // });

        // const subscription: StripeSubscription = await subscriptionResponse.json();
        const claims: IdTokenClaims = accessToken.idTokenClaims as IdTokenClaims;
        setFields({
            displayName: claims.name ?? "",
            email: user.username,
            givenName: claims.givenName ?? "",
            surname: claims.surname ?? "",
            city: claims.city ?? "",
            state: claims.state ?? "",
            // subscriptionType: subscription.items[0].price.product.name,
            // subscriptionQuantity: subscription.items[0].quantity ?? 1,
            // monthlyCost: subscription.items[0].price.unitAmount / 100,
        });
    }

    useEffect(() => {
        SetAccountFields();
    }, []);

    function handleAccountChange(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        setFields(prevData => ({
            ...prevData,
            [e.target.name]: e.target.value
        }));
    }

    async function SaveChanges() {
        setEditing(false);
        const accessToken = await instance.acquireTokenSilent({
            scopes: [USER_READWRITE_MS_GRAPH_SCOPE],
            account: user
        });
        const body = {
            "displayName": fields.displayName,
            "givenName": fields.givenName,
            "surname": fields.surname
        };
        console.log(accessToken);
        const response = await fetch(USER_GRAPH_URL, {
            method: "PATCH", // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken.accessToken,
                "Host": "graph.microsoft.com"
            },
            body: JSON.stringify(body)
        });
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", overflow: "auto", bgcolor: colors.SchemesSurfaceContainerLow, overflowX: "hidden" }}>
            <AppBar position='sticky' elevation={0} sx={{ bgcolor: colors.SchemesSurfaceContainerLow }}>
                <Toolbar sx={{ margin: `${theme.spacing(2)}`, marginBottom: 0, bgcolor: "white", borderRadius: theme.spacing(4), height: "84px" }}>
                    <Typography flexGrow={1} variant="h4">My Account</Typography>
                    {editing ?
                        <IconButton color="primary" onClick={() => SaveChanges()}>
                            <Save />
                        </IconButton>
                        :
                        <IconButton color="primary" onClick={() => { setEditing(true) }}>
                            <Edit />
                        </IconButton>
                    }
                </Toolbar>
            </AppBar>
            <Box sx={{ width: "100%", padding: `${theme.spacing(2)}` }} display="flex" flexDirection="column" gap={theme.spacing(2)} flex={1}>
                <Card sx={{
                    padding: theme.spacing(2),
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Personal Info</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField disabled={!editing} fullWidth label="Display Name" name="displayName" value={fields.displayName} onChange={handleAccountChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField disabled={!editing} fullWidth label="Given Name" name="givenName" value={fields.givenName} onChange={handleAccountChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField disabled={!editing} fullWidth label="Surname" name="surname" value={fields.surname} onChange={handleAccountChange} />
                        </Grid>
                        {
                            fields.email != "" &&
                            <Grid item xs={12}>
                                <Tooltip title="For security purposes, you cannot change your email.">
                                    <TextField disabled fullWidth label="Email" name="email" value={fields.email} onChange={handleAccountChange} />
                                </Tooltip>
                            </Grid>
                        }
                    </Grid>
                </Card>
                {/* <Card sx={{
                    padding: theme.spacing(2),
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Subscription</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography fontWeight={400} fontSize="16px" color={colors.grey}>Subscription Type: </Typography>
                            <Typography fontWeight={400}>{fields.subscriptionType}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="contained" target="_blank" href={STRIPE_MANAGEMENT_URL} >Manage</Button>
                        </Grid>
                    </Grid>
                </Card> */}
                <Box display="flex" flexDirection="row" paddingBottom={theme.spacing(4)}>
                    <Box flex={1} />
                    <Button startIcon={<Logout />} variant="contained" color="secondary" sx={{ bgcolor: colors.violetLighter, color: colors.violetDark, width: 192 }} onClick={() => instance.logoutRedirect()} >Logout</Button>
                    <Box flex={1} />
                </Box>
            </Box>
        </Box>
    );
}
